import React from 'react';

import BaseLayout from '../components/layouts/BaseLayout';
import Stage from '../components/layouts/Stage';
import StageIntro from '../components/elements/StageIntro';
import { Section } from '../components/layouts/Containers';
import { Link } from '../components/elements/ButtonsAndLinks';
import { Headline } from '../components/elements/Typography';

const PrivacyPage = () => (
    <BaseLayout
        title="Datenschutz"
        description="Datenschutzerklärung der Staatlichen Toto-Lotto GmbH"
    >
        <Stage>
            <StageIntro headline={{ text: 'Datenschutz' }} />
        </Stage>
        <Section container="s">
            <Headline level="h3">Spielteilnehmer und Kunden</Headline>
            <Link
                gap="xxl"
                to="https://www.lotto-bw.de/datenschutz;jsessionid=ilVWbs7GJ-5qfocdfZRIV4JOdE4y26h5MfYnwiqo5nRnJxCOLsY8!-1637338707!stipf5!8001!-1!-118843!stipf1!8001!-1"
                target="_blank"
                rel="noopener noreferrer"
            >
                Datenschutzerklärung der Staatlichen Toto-Lotto GmbH - Spielteilnehmer und Kunden
                (PDF 0,07 MB){' '}
            </Link>
        </Section>
    </BaseLayout>
);

export default PrivacyPage;
